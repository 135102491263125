import React from 'react';
import AwardsA from './blocks/Awards/AwardsA';
import CertificationsA from './blocks/Certifications/CertificationsA';
import ContactA from './blocks/Contact/ContactA';
import EducationA from './blocks/Education/EducationA';
import HeadingB from './blocks/Heading/HeadingB';
import HobbiesA from './blocks/Hobbies/HobbiesA';
import LanguagesA from './blocks/Languages/LanguagesA';
import PageContext from '../contexts/PageContext';
import ProjectsA from './blocks/Projects/ProjectsA';
import SkillsA from './blocks/Skills/SkillsA';
import WorkA from './blocks/Work/WorkA';
import ObjectiveA from './blocks/Objective/ObjectiveA';
import ReferencesB from './blocks/References/ReferencesB';
import LogoA from './blocks/CompanyLogo/LogoA';

const Blocks = {
  objective: ObjectiveA,
  work: WorkA,
  education: EducationA,
  projects: ProjectsA,
  awards: AwardsA,
  certifications: CertificationsA,
  skills: SkillsA,
  hobbies: HobbiesA,
  languages: LanguagesA,
  references: ReferencesB,
};

const Orientalent = ({ data }) => {
  const layout = data.metadata.layout.orientalent;

  return (
    <PageContext.Provider value={{ data, heading: HeadingB }}>
      <div
        id="page"
        className="px-14 pt-2 pb-10 rounded"
        style={{
          fontFamily: data.metadata.font,
          color: data.metadata.colors.text,
          backgroundColor: data.metadata.colors.background,
        }}
      >
        <div className="grid grid-cols-12 gap-6">
          {data.profile.photograph && (
            <div className="self-center col-span-4">
              <img
                className="w-44 h-44 rounded-full mx-auto object-cover"
                src={data.profile.photograph}
                alt={data.profile.firstName}
              />
            </div>
          )}

          <div
            className={`${
              data.profile.photograph !== '' ? 'col-span-8' : 'col-span-12'
            }`}
          >
            <div
              className="h-44 rounded flex flex-col justify-center relative"
              style={{
                backgroundColor: data.metadata.colors.primary,
                color: data.metadata.colors.background,
              }}
            >
              <div className="flex flex-col justify-center mx-8 my-6 ">
                <LogoA
                  companyId={data.companyId}
                  className="absolute w-20 right-10 top-5"
                />
                <h1 className="text-4xl font-bold leading-tight">
                  {data.profile.firstName} {data.profile.lastName}
                </h1>
                <div className="text-lg font-medium tracking-wide">
                  {data.profile.subtitle}
                </div>
              </div>
            </div>
          </div>

          <div className="col-span-4">
            <div className="grid gap-4">
              <ContactA />

              {layout[0] &&
                layout[0].map((x) => {
                  const Component = Blocks[x];
                  return Component && <Component key={x} />;
                })}
            </div>
          </div>

          <div className="col-span-8">
            <div className="grid gap-4">
              {layout[1] &&
                layout[1].map((x) => {
                  const Component = Blocks[x];
                  return Component && <Component key={x} />;
                })}
            </div>
          </div>
        </div>
      </div>
    </PageContext.Provider>
  );
};

export default Orientalent;
