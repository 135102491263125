import React, { memo, useEffect, useState } from 'react';
import firebase from 'gatsby-plugin-firebase';

const LogoA = ({ companyId, className }) => {
  const [companyInfo, setCompanyInfo] = useState();

  useEffect(() => {
    const companyRef = 'companies';

    firebase
      .database()
      .ref(`companies/${companyId}`)
      .on('value', (snapshot) => {
        if (snapshot.val()) {
          const data = snapshot.val();
          setCompanyInfo(data);
        }
      });

    return () => {
      firebase.database().ref(companyRef).off();
    };
  }, [companyId]);

  return (
    <div className={className}>
      {companyInfo && companyInfo.replaceContact && companyInfo.logo && (
        <img src={companyInfo.logo} alt="company logo" />
      )}
    </div>
  );
};

export default memo(LogoA);
